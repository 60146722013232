import React from 'react'

export default function SidebarDisconnectAdmin() {

    const disconnect = () => {
        localStorage.removeItem('admin_token');
        window.location.href = '/admin/login';
    };

    return (
        <li className="nav-item">
            <div className="nav-link collapsed" onClick={disconnect}>
                <i className="bi bi-arrow-left"></i>
                <span>Se deconnecter</span>
            </div>
        </li>
    )
}
