import React, { useState } from 'react';
import Card from '../../components/Card';
import Forminput from '../../components/Forminput';
import Formbutton from '../../components/Formbutton';
import Form from '../../components/Form';

import PhoneInput from '../../components/PhoneInput';
import FormError from '../../components/FormError';
import FormSuccess from '../../components/FormSuccess';
import { BASE_URL } from '../../Config';
import axios from 'axios';

const AdminRegister = () => {

    const token = localStorage.getItem('admin_token') || null

    if (token !== null) {
        window.location.href = '/admin/dashboard';
    }

    const URL = BASE_URL + '/api/v1/auth/admin/register';

    const [formResponse, setFormResponse] = useState('');
    const [formResponseSuccess, setFormResponseSuccess] = useState('');

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');



    const handleRegister = () => {
        setFormResponseSuccess('')
        setFormResponse('');
        if (username === '') {
            setFormResponse('Saisissez votre nom et le prénom svp');
        } else if (email === '') {
            setFormResponse('Saisissez votre email svp');
        } else if (phone === '' || phone.length < 10) {
            setFormResponse('Completez votre numéro du téléphone svp');
        } else if (nom === '') {
            setFormResponse('Saisissez votre nom svp');
        } else if (prenom === '') {
            setFormResponse('Saisissez votre prenom svp');
        } else if (password === '') {
            setFormResponse('Saisir un mot de passe');
        } else if (password !== passwordConfirm) {
            setFormResponse('Les deux mots de passes sont identiques');
        } else {
            let data = {
                username: username,
                email: email,
                phone: phone,
                nom: nom,
                prenom: prenom,
                password: password
            }

            axios.post(URL, data)
                .then(response => {
                    if (response.data.error) {
                        setFormResponse(response.data.error);
                    } else {
                        setFormResponseSuccess(response.data.username + ' . Votre inscription reussie')
                    }
                })
                .catch(error => {
                    setFormResponse('Erreur serveur : ' + error.message);
                });
        }
    };

    return (
        <div>
            <Card title="Inscription" texte="Espace administration">
                <Form onSubmit={handleRegister}>
                    <Forminput
                        labelText="Nom utilisateur"
                        name="username"
                        id="username"
                        type="text"
                        invalidFeedbackText="Veuillez inserer votre nom d'utilisateur"
                        onChange={setUsername}
                        placeHolder="Nom utilisateur"
                    />
                    <Forminput
                        labelText="Email"
                        name="email"
                        id="email"
                        type="email"
                        invalidFeedbackText="Veuillez inserer votre email"
                        onChange={setEmail}
                        placeHolder="Adresse email"
                    />
                    <PhoneInput
                        labelText="Téléphone"
                        name="phone"
                        id="phone"
                        type="text"
                        onChange={setPhone}

                    />
                    <Forminput
                        labelText="Nom de famille"
                        name="nom"
                        id="nom"
                        type="text"
                        invalidFeedbackText="Veuillez inserer votre nom"
                        onChange={setNom}
                        placeHolder="Votre nom de famille"
                    />
                    <Forminput
                        labelText="Prenom"
                        name="prenom"
                        id="prenom"
                        type="text"
                        invalidFeedbackText="Veuillez inserer votre prenom"
                        onChange={setPrenom}
                        placeHolder="Votre prenom"
                    />
                    <Forminput
                        labelText="Mot de passe"
                        name="password"
                        id="password"
                        type="password"
                        invalidFeedbackText="Veuillez inserer votre mot de passe"
                        onChange={setPassword}
                        placeHolder="Mot de passe"
                    />
                    <Forminput
                        labelText="Confirmation mot de passe"
                        name="password-confirm"
                        id="password-confirm"
                        type="password"
                        invalidFeedbackText="Veuillez confirmer votre mot de passe"
                        onChange={setPasswordConfirm}
                        placeHolder="Confirmer mot de passe"
                    />
                    {formResponse && <FormError message={formResponse} />}
                    {formResponseSuccess && <FormSuccess message={formResponseSuccess} />}


                    <Formbutton
                        color="danger"
                        type="submit"
                        texte="Creer un compte administrateur"
                    />
                </Form>
            </Card>

        </div>
    );
};

export default AdminRegister;
