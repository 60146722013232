import React, { useState, useEffect } from 'react';

const FormSwitchCheckbox = ({ id, defaultChecked, onChange, texte }) => {
    const [isChecked, setIsChecked] = useState(defaultChecked || false);

    useEffect(() => {
        setIsChecked(defaultChecked || false);
    }, [defaultChecked]);

    const handleChange = (event) => {
        const newCheckedValue = event.target.checked;
        setIsChecked(newCheckedValue);

        if (onChange) {
            onChange(newCheckedValue);
        }
    };

    return (
        <div className='col-12'>
            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={id}
                    checked={isChecked}
                    onChange={handleChange}
                />
                <label className="form-check-label" htmlFor={id}>
                    {texte}
                </label>
            </div>
        </div>

    );
};

export default FormSwitchCheckbox;
