import React from 'react';

const Form = ({ onSubmit, children }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSubmit) {
            onSubmit();
        }
    };

    return (
        <form className="row g-3 needs-validation" onSubmit={handleSubmit} noValidate>
            {children}
        </form>
    );
};

export default Form;
