import React from 'react'

export default function Sidebar({ children }) {
    return (

        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                {children}
            </ul>
        </aside>
    )
}
