import React from 'react'
import Sidebar from './Sidebar'
import SidebarElement from './SidebarElement'
import SidebarDisconnectAdmin from './SidebarDisconnectAdmin'

export default function AdminSidebar() {
    return (
        <Sidebar>
            <SidebarElement texte="Dashboard" url="/admin/dashboard" icone="bi-grid" />
            <SidebarElement texte="Historique" url="/admin/sms" icone="bi-grid" />
            <SidebarElement texte="Etat numerique" url="/admin/sms/count" icone="bi-grid" />
            <SidebarDisconnectAdmin />
        </Sidebar>
    )
}
