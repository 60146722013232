
import io from 'socket.io-client';
import { BASE_URL } from '../Config';

const device_id = 'admin';

class SocketManager {
    static instance = null;
    socket = null;
    constructor() {
        if (!SocketManager.instance) {
            SocketManager.instance = this;
            this.socket = io(BASE_URL, {
                extraHeaders: {
                    Authorization: device_id,
                },
            });

            this.socket.on('connect', () => {
                //console.log("Connected to server");
            })
        }
        return SocketManager.instance;
    }

    getSocket() {
        return this.socket;
    }
}

export default SocketManager;

