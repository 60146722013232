import React, { useState, useEffect } from 'react';
import AdminHeader from '../../components/dashboard/AdminHeader';
import AdminSidebar from '../../components/dashboard/AdminSidebar';
import { BASE_URL } from '../../Config';
import axios from 'axios';

export default function AdminSMSCount() {
    const token = localStorage.getItem('admin_token') || null;

    if (token === null) {
        window.location.href = '/admin/login';
    }

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                let url = `${BASE_URL}/api/v1/admin/sms/by-operator`;
                const queryParams = [];
                if (startDate.trim() !== '') {
                    queryParams.push(`debut=${encodeURIComponent(startDate)}`);
                }
                if (endDate.trim() !== '') {
                    queryParams.push(`fin=${encodeURIComponent(endDate)}`);
                }
                if (queryParams.length > 0) {
                    url += '?' + queryParams.join('&');
                }
                const response = await axios.get(url, { headers: { Authorization: 'Bearer ' + token } });
                const data = response.data;

                // Calculate the total using the reduce function
                const newTotal = data.reduce((acc, element) => acc + element._count.operateur, 0);

                // Update the state using the functional form of setTotal
                setTotal((prevTotal) => prevTotal + newTotal);

                setData(data);
            } catch (error) {
                console.error('Erreur:', error.message);
            }
        };

        if (startDate !== '' && endDate !== '') {
            setTotal(0)
            getData();
        }
    }, [startDate, endDate, token]);

    return (
        <div>
            <AdminHeader />
            <AdminSidebar />

            <section className="section dashboard">
                <div id="main" className="main">
                    <div className="pagetitle">
                        <h1>Etat numérique des SMS</h1>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-md-6 mt-3">
                            <input
                                className="form-control rounded-0"
                                type="date"
                                placeholder="Date de début"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="col-md-6 mt-3 ">
                            <input
                                className="form-control rounded-0"
                                type="date"
                                placeholder="Date de fin"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <p>
                        {startDate && `Du ${startDate}`} {endDate && `- Au ${endDate}`}
                    </p>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr className="bg-primary text-white">
                                    <th>OPERATEUR</th>
                                    <th>NOMBRE SMS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    data.length > 0 &&
                                    data.map((element, index) => (
                                        <tr key={index} className="bg-info">
                                            <td >Nombre SMS {element.operateur}</td>
                                            <th>{element._count.operateur}</th>
                                        </tr>
                                    ))}
                                <tr >
                                    <th className="bg-info">Total SMS</th>
                                    <th className="bg-primary text-white"><h1><b>{total}</b></h1></th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    );
}
