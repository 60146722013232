import React, { useState, useEffect } from 'react';
const style = {
    width: '160px',
    height: '80px',
};

export default function Header() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        document.body.classList.toggle('toggle-sidebar', isSidebarOpen);
    }, [isSidebarOpen]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <a href="/" >
                    <img src={`${window.location.origin}/4.png`} className='me-5 ms-5' style={style} alt="Tydal sms" />
                </a>
                <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
            </div>
        </header>
    )
}
