import React from 'react'
import StatIcone from './StatIcone'

export default function DashCard({ titre, nombre, connect, styles }) {
    return (
        <div className="col-md-4">
            <div className={`card info-card ${styles}`}>
                <div className="card-body">
                    <h5 className="card-title">{titre} <span> </span></h5>
                    <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-telephone-forward-fill"></i>
                        </div>
                        <div className="ps-3">
                            <h6>Actifs {nombre}</h6>
                            <span className="text-success small pt-1 fw-bold"><StatIcone color="success" /> Connectés {connect}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
