import React, { useState } from 'react';
import Card from '../components/Card';
import Forminput from '../components/Forminput';
import Formbutton from '../components/Formbutton';
import Form from '../components/Form';
import FormSelectBox from '../components/FormSelectBox';
import PhoneInput from '../components/PhoneInput';
import FormError from '../components/FormError';
import FormSuccess from '../components/FormSuccess';
import { BASE_URL } from '../Config';
import axios from 'axios';
const Register = () => {
    const URL = BASE_URL + '/api/v1/auth/device/register';
    const options = [
        { value: 'djezzy', label: 'Djezzy' },
        { value: 'mobilis', label: 'Mobilis' },
        { value: 'nedjma', label: 'Nedjma' },
    ];

    const [formResponse, setFormResponse] = useState('');
    const [formResponseSuccess, setFormResponseSuccess] = useState('');

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [operateur, setOperateur] = useState(options[0].value);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const handleSelectChange = (selectedValue) => {
        setOperateur(selectedValue);
    };

    const handleRegister = () => {
        setFormResponseSuccess('')
        setFormResponse('');
        if (username === '') {
            setFormResponse('Saisissez votre nom et le prénom svp');
        } else if (email === '') {
            setFormResponse('Saisissez votre email svp');
        } else if (phone === '' || phone.length < 10) {
            setFormResponse('Completez votre numéro du téléphone svp');
        } else if (password === '') {
            setFormResponse('Saisir un mot de passe');
        } else if (password !== passwordConfirm) {
            setFormResponse('Les deux mots de passes sont identiques');
        } else if ((phone.startsWith('07') && (operateur === "mobilis" || operateur === "nedjma")) || (phone.startsWith('06') && (operateur === "djezzy" || operateur === "nedjma")) || (phone.startsWith('05') && (operateur === "mobilis" || operateur === "djezzy"))) {
            setFormResponse('L\'opérateur ne correspond pas au numéro de téléphone');
        } else if (!(phone.startsWith('05')) && !(phone.startsWith('06')) && !(phone.startsWith('07'))) {
            setFormResponse('Le numéro du téléphone ne fait partie des GSM connus en Algérie');
        } else {
            let data = {
                username: username,
                email: email,
                phone: phone,
                operateur: operateur,
                password: password
            }

            axios.post(URL, data)
                .then(response => {
                    if (response.data.error) {
                        setFormResponse(response.data.error);
                    } else {
                        setFormResponseSuccess(response.data.username + ' . Votre inscription reussie')
                    }
                })
                .catch(error => {
                    setFormResponse('Erreur serveur : ' + error.message);
                });
        }
    };

    return (
        <div>
            <Card title="Creer un nouveau compte" texte="">
                <Form onSubmit={handleRegister}>
                    <Forminput
                        labelText="Nom utilisateur"
                        name="username"
                        id="username"
                        type="text"
                        invalidFeedbackText="Veuillez inserer votre nom et prénom"
                        onChange={setUsername}
                        placeHolder="Nom et prénom"
                    />
                    <Forminput
                        labelText="Email"
                        name="email"
                        id="email"
                        type="email"
                        invalidFeedbackText="Veuillez inserer votre email"
                        onChange={setEmail}
                        placeHolder="Adresse email"
                    />
                    <PhoneInput
                        labelText="Téléphone"
                        name="phone"
                        id="phone"
                        type="text"
                        onChange={setPhone}

                    />
                    <FormSelectBox
                        labelText="Opérateur"
                        id="operator"
                        options={options}
                        value={operateur}
                        onChange={handleSelectChange}
                    />
                    <Forminput
                        labelText="Mot de passe"
                        name="password"
                        id="password"
                        type="password"
                        invalidFeedbackText="Veuillez inserer votre mot de passe"
                        onChange={setPassword}
                        placeHolder="Mot de passe"
                    />
                    <Forminput
                        labelText="Confirmation mot de passe"
                        name="password-confirm"
                        id="password-confirm"
                        type="password"
                        invalidFeedbackText="Veuillez confirmer votre mot de passe"
                        onChange={setPasswordConfirm}
                        placeHolder="Confirmer mot de passe"
                    />
                    {formResponse && <FormError message={formResponse} />}
                    {formResponseSuccess && <FormSuccess message={formResponseSuccess} />}


                    <Formbutton
                        color="danger"
                        type="submit"
                        texte="Creer un compte"
                    />
                    <div className="col-12">
                        <p className="small mb-0">Vous avez deja un compte? <a href="/login">Se connecter</a></p>
                    </div>
                </Form>
            </Card>

        </div>
    );
};

export default Register;
