import React from 'react';


const style = {
    width: '300px',
    height: '150px',
};

const Card = ({ children, title, texte }) => {
    return (
        <main>
            <div className="container">
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <a href="/">
                                        <img src={`${window.location.origin}/4.png`} style={style} alt="Tydal sms" />
                                    </a>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">{title}</h5>
                                            <p className="text-center small">{texte}</p>
                                        </div>
                                        {children}
                                    </div>
                                </div>
                                <div className="credits">
                                    <b>By <a href="https://tydalmachine.dz/" className="text-info">Tydal machine</a></b>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>

    );
};

export default Card;
