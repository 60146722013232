import React from 'react'

export default function SidebarElement({ texte, url, icone }) {
    return (
        <li className="nav-item">
            <a className="nav-link collapsed" href={url}>
                <i className={`bi ${icone}`}></i>
                <span>{texte}</span>
            </a>
        </li>
    )
}