import React from 'react';

export default function Forminput({ labelText, name, id, type, invalidFeedbackText, value, onChange, placeHolder }) {
    return (
        <div className="col-12">
            <label htmlFor={id} className="form-label">{labelText}</label>
            <input type={type} name={name} className="form-control" value={value} id={id} onChange={(e) => onChange(e.target.value)} placeholder={placeHolder} required />
            <div className="invalid-feedback">{invalidFeedbackText}</div>
        </div>
    );
}
