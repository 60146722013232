import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import AdminSidebar from "../../components/dashboard/AdminSidebar";
import AdminHeader from "../../components/dashboard/AdminHeader";

const AdminSMSHome = () => {

    const token = localStorage.getItem('admin_token') || null
    if (token === null) {
        window.location.href = '/admin/login';
    }

    const [smsList, setSmsList] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 100;

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (startDate !== '' && endDate !== '') {
                    const response = await axios.get(
                        `${BASE_URL}/api/v1/admin/sms/${startDate}/${endDate}/${currentPage}/${pageSize}`,
                        { headers: { Authorization: "Bearer " + token } }
                    );

                    const { sms, count } = response.data;
                    setSmsList(sms);
                    setTotalItems(count);
                }
            } catch (error) {
                console.error('Erreur:', error.message);
            }
        };

        fetchData();
    }, [startDate, endDate, currentPage, token]);

    const handleSearch = (e) => {
        e.preventDefault();
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div>
            <AdminHeader />
            <AdminSidebar />
            <section className="section dashboard">

                <div id="main" className='main' >
                    <div className="pagetitle">
                        <h1>Historique SMS</h1>
                    </div>

                    <form className="row mb-3 mt-3" onSubmit={handleSearch}>
                        <div className="col-md-4 mt-3">
                            <input
                                className="form-control"
                                type="date"
                                placeholder="Date de début"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="col-md-4 mt-3">
                            <input
                                className="form-control"
                                type="date"
                                placeholder="Date de fin"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>

                        <div className="col-md-4">
                            <button className="btn btn-primary w-100 mt-3" type="submit">Rechercher</button>
                        </div>
                    </form>

                    <h6>Total SMS: {totalItems}</h6>
                    <div className="table-responsive">
                        <table className='table '>
                            <thead>
                                <tr>
                                    <th>DEVICE</th>
                                    <th>CLIENT</th>
                                    <th>STATUT</th>
                                    <th>GSM RESULT</th>
                                    <th>MESSAGE</th>
                                    <th>DATE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {smsList && smsList.map(sms => (
                                    <tr key={sms.id}>
                                        <td>{sms.device_id}</td>
                                        <td>{sms.to}</td>
                                        <td>{sms.status}</td>
                                        <td>{sms.result}</td>
                                        <td>{sms.message}</td>
                                        <td>{sms.created_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {Array.from({ length: Math.ceil(totalItems / pageSize) }, (_, index) => (
                            <button className={`btn btn-outline-info ms-1 mt-1 ${index + 1 === currentPage ? 'active' : ''}`} key={index + 1} onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )

}

export default AdminSMSHome


