import React, { useState } from 'react';
import Card from '../../components/Card';
import Forminput from '../../components/Forminput';
import Formbutton from '../../components/Formbutton';
import Form from '../../components/Form';
import PhoneInput from '../../components/PhoneInput';
import FormError from '../../components/FormError';
import axios from 'axios';
import { BASE_URL } from '../../Config';

const AdminLogin = () => {

    const token = localStorage.getItem('admin_token') || null

    if (token !== null) {
        window.location.href = '/admin/dashboard';
    }

    const URL = BASE_URL + '/api/v1/auth/admin/login';

    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');

    const [formResponse, setFormResponse] = useState('');

    const handleLogin = () => {
        setFormResponse('');

        if (phone === '' || phone.length < 10) {
            setFormResponse('Completez votre numéro du téléphone svp');
        } else if (password === '') {
            setFormResponse('Saisir un mot de passe');
        } else {
            let data = {
                phone: phone,
                password: password
            }

            axios.post(URL, data)
                .then(response => {
                    if (response.data.error) {
                        setFormResponse(response.data.error);
                    } else {
                        localStorage.setItem('admin_token', response.data.token);
                        window.location.href = '/admin/dashboard';
                    }
                })
                .catch(error => {
                    setFormResponse('Erreur serveur: ' + error.message);
                });
        }
    };

    return (
        <div>
            <Card title="Se connecter" texte="Espace administration">
                <Form onSubmit={handleLogin}>

                    <PhoneInput
                        labelText="Téléphone"
                        name="phone"
                        id="phone"
                        type="text"
                        onChange={setPhone}
                    />
                    <Forminput
                        labelText="Mot de passe"
                        name="password"
                        id="password"
                        type="password"
                        invalidFeedbackText="Veuillez inserer votre mot de passe"
                        onChange={setPassword}
                    />
                    {formResponse && <FormError message={formResponse} />}
                    <Formbutton
                        color="danger"
                        type="submit"
                        texte="Se connecter"
                    />
                </Form>
            </Card>

        </div>
    );
};

export default AdminLogin;

