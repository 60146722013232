import React from 'react';

export default function FormSuccess({ message }) {

    return (
        <div className='col-12'>
            <div className='alert alert-success'><b>{message}</b></div>
        </div>
    )
}


