import React from 'react';
import MaskedInput from 'react-text-mask';

const PhoneInput = ({ labelText, name, id, value, onChange }) => {
    const expression = ['0', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    return (
        <div className="col-12">
            <label htmlFor={id} className="form-label">{labelText}</label>
            <MaskedInput
                mask={expression}
                guide={false}
                id={id}
                name={name}
                value={value}
                className="form-control"
                placeholder="0_________"
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
};

export default PhoneInput;

