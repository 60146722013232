import React from 'react';

export default function FormError({ message }) {

    return (
        <div className='col-12'>
            <div className='alert alert-danger'><b>{message}</b></div>
        </div>
    )
}


