import React, { useEffect, useState } from 'react';
import StatIcone from '../StatIcone'
import { BASE_URL } from '../../Config';
import axios from 'axios';

import FormSwitchCheckbox from '../FormSwitchCheckbox';
import Form from '../Form';
import Formbutton from '../Formbutton';
import Forminput from '../Forminput';
import FormError from '../FormError';
import FormSuccess from '../FormSuccess'

const DeviceItem = ({ device, socketManager }) => {

    const UPDATE_URL = BASE_URL + '/api/v1/admin/devices/update'
    const DELETE_URL = BASE_URL + '/api/v1/admin/devices/delete'

    const token = localStorage.getItem('admin_token') || null
    if (token === null) {
        window.location.href = '/admin/login';
    }

    /**** informations du device *** */
    /******************************* */
    const [deviceName, setDeviceName] = useState('');
    const [batterie, setBaterie] = useState(0)
    const [carrier, setCarrier] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const [formResponseErrorDelate, setFormResponseErrorDelate] = useState('');
    const [formResponseSuccessDelate, setFormResponseSuccessDelate] = useState('');

    //const [refresh, setRefresh] = useState(true);
    /*** reponses form config 2 */
    const [idd, setIdd] = useState(0)

    const [isActive, setIsActive] = useState(false); /** pour remplacer stat ** */
    const [username, setUsername] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [phone, setPhone] = useState('');
    const [dstat, setDstat] = useState(false);
    const [dnbr, setDnbr] = useState(0);
    const [mstat, setMstat] = useState(false);
    const [mnbr, setMnbr] = useState(0);
    const [nstat, setNstat] = useState(false);
    const [nnbr, setNnbr] = useState(0);

    const [formResponseErrorUpdate, setFormResponseErrorUpdate] = useState('');
    const [formResponseSuccessUpdate, setFormResponseSuccessUpdate] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const [deviceCountd, seteDviceCountd] = useState(0);
    const [deviceCountm, seteDviceCountm] = useState(0);
    const [deviceCountn, seteDviceCountn] = useState(0);
    const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(false);

    /****** champs a modifier ****** */
    const updateDevice = () => {
        const data = {
            id: parseInt(idd),
            username: username,
            email: email,
            password: '',
            isActive: isActive
        }
        if (password !== '') {
            if (password === passwordConfirm) {
                data.password = password
            } else {
                setFormResponseErrorUpdate('Le mot de passe et la confirmation sont pas identiques')
                return
            }
        }

        axios.post(UPDATE_URL, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.data.error) {
                    setFormResponseErrorUpdate(response.data.error);
                } else {
                    setFormResponseErrorUpdate('');
                    setFormResponseSuccessUpdate('Mise à jour réussie ! ' + response.data.username)
                }
            })
            .catch(error => {
                setFormResponseErrorUpdate('Erreur serveur: ' + error.message);
            });
    }

    const deleteDevice = () => {
        axios.get(DELETE_URL + '/' + idd, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.data.error) {
                    setFormResponseErrorDelate(response.data.error);
                } else {
                    setFormResponseSuccessDelate(response.data.message)
                    setIsDeleteSuccessful(true);
                }
            })
            .catch(error => {
                setIsDeleteSuccessful(true);
                setFormResponseErrorDelate('Erreur serveur: ' + error.message);
            });
    }

    const resetArea = () => {
        setFormResponseErrorUpdate('')
        setFormResponseSuccessUpdate('')
        setFormResponseErrorDelate('')
        setFormResponseSuccessDelate('')
    }


    const getDeviceInfos = () => {
        const socket = socketManager.getSocket();
        socket.emit("device-infos", { devId: device.dev_id });
    }

    useEffect(() => {
        const socket = socketManager.getSocket();
        const statOperateurs = `STAT-${device.dev_id}`;

        setIdd(device.id)
        setIsActive(device.is_active)
        setUsername(device.username)
        setPhone(device.phone)
        setDstat(device.d_send)
        setDnbr(device.limit_d)
        setMstat(device.m_send)
        setMnbr(device.limit_m)
        setNstat(device.n_send)
        setNnbr(device.limit_n)
        setEmail(device.email)

        socket.on(statOperateurs, (status) => {
            if (status.connect) {
                setIsConnected(status.connect);
            }
            if (status.count_d) {
                seteDviceCountd(status.count_d)
            }
            if (status.count_m) {
                seteDviceCountm(status.count_m)
            }
            if (status.count_n) {
                seteDviceCountn(status.count_n)
            }
        });

        socket.on(`info_success_${device.dev_id}`, (data) => {
            console.log(data);
            if (data.phoneNumber) {
                setPhoneNumber(data.phoneNumber);
            }
            if (data.carrier) {
                setCarrier(data.carrier)
            }
            if (data.batteryLevel) {
                setBaterie(data.batteryLevel)
            }
            if (data.manufacturer) {
                setDeviceName(data.manufacturer)
            }

        })

        socket.on(`info_error_${device.dev_id}`, (data) => {
            console.log(data);
        })

    }, [device, socketManager]);

    return (

        (isDeleteSuccessful ?
            <tr className="table-success">
                <td colSpan={10} className="alert alert-dismissible fade show w-100 rounded-0" role="alert">
                    <h6>
                        <i className="bi bi-check-circle me-1"></i>
                        {formResponseSuccessDelate} {formResponseErrorDelate}
                        <button type="button" className="btn btn-sm text-danger" data-bs-dismiss="alert" aria-label="Close"><i className="bi bi-x-circle-fill me-1"></i></button>
                    </h6>

                </td>
            </tr> :
            <tr>
                <td>
                    {isActive ? <StatIcone type="phone-vibrate-fill" color="info" /> : <StatIcone type="phone-vibrate" color="danger" />} {username}
                    <br /><b>{email}</b>
                </td>
                <td className="text-center">
                    {isConnected ? <StatIcone type="wifi" color="info" /> : <StatIcone type="wifi-off" color="danger" />}
                </td>
                <td>
                    <b> {phone}</b>
                </td>
                <td>
                    {dstat ? <StatIcone type="check-circle-fill" color="info" /> : <StatIcone color="danger" />}
                    {dnbr} - <b> {deviceCountd}</b>
                </td>
                <td>
                    {mstat ? <StatIcone type="check-circle-fill" color="info" /> : <StatIcone color="danger" />}
                    {mnbr} - <b> {deviceCountm}</b>
                </td>
                <td>
                    {nstat ? <StatIcone type="check-circle-fill" color="info" /> : <StatIcone color="danger" />}
                    {nnbr} - <b> {deviceCountn}</b>
                </td>

                <td>
                    <button className="btn btn-sm btn-outline-info" data-bs-toggle="modal" data-bs-target={`#modal-change-${idd}`} type="button"><i className="bi bi-pencil-fill me-1"></i> Modifier</button>
                    <div className="modal fade" id={`modal-change-${idd}`} tabIndex="-1" >
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title text-center">
                                        <b>CONF SIM {phone}</b>
                                    </h5>

                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <Form onSubmit={updateDevice}>
                                        <FormSwitchCheckbox defaultChecked={isActive} onChange={setIsActive} texte="Activation device" />
                                        <Forminput
                                            labelText={`Nom utilisateur : ${username} - ${device.id}`}

                                            type="text"
                                            value={username}
                                            invalidFeedbackText=""
                                            onChange={setUsername}
                                            placeHolder=""
                                        />
                                        <Forminput
                                            labelText={`Email : ${email}`}

                                            type="email"
                                            value={email}
                                            invalidFeedbackText=""
                                            onChange={setEmail}
                                            placeHolder=""
                                        />
                                        <Forminput
                                            labelText="Nouveau Mot de passe"

                                            type="text"
                                            value={password}
                                            invalidFeedbackText=""
                                            onChange={setPassword}
                                            placeHolder=""
                                        />
                                        <Forminput
                                            labelText="Confirmation mot de passe"
                                            type="text"
                                            value={passwordConfirm}
                                            invalidFeedbackText=""
                                            onChange={setPasswordConfirm}
                                            placeHolder=""
                                        />
                                        {formResponseErrorUpdate && <FormError message={formResponseErrorUpdate} />}
                                        {formResponseSuccessUpdate && <FormSuccess message={formResponseSuccessUpdate} />}
                                        <Formbutton
                                            color="success"
                                            type="submit"
                                            texte="Save config"
                                        />
                                    </Form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={resetArea}>Fermer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <button className="btn btn-sm btn-outline-danger" onClick={deleteDevice}><i className="bi bi-trash-fill me-1"></i>Suppr</button>
                </td>
                <td>

                    <button className="btn btn-sm btn-outline-info" data-bs-toggle="modal" data-bs-target={`#modal-info-${idd}`} type="button"><StatIcone type="info-circle" color="info" /> Infos</button>
                    <div className="modal fade" id={`modal-info-${idd}`} tabIndex="-1" >
                        <div className="modal-dialog modal-lg modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title text-center">
                                        <b>Informations compte {phone}</b>
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <ul className="list-group rounded-0">
                                        <li className="list-group-item">
                                            <button className="btn btn-sm btn-info rounded-0 w-100 p-2" onClick={getDeviceInfos}><StatIcone type="info-circle" color="info" />Demander informations de l'apparel</button>
                                        </li>
                                        <li className="list-group-item"> </li>
                                        <li className="list-group-item">Numero actuel : {phoneNumber}</li>
                                        <li className="list-group-item">Reference :    {deviceName}</li>
                                        <li className="list-group-item">Batterie    {batterie}</li>
                                        <li className="list-group-item">Operateur  {carrier}</li>
                                    </ul>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>

            </tr>)
    );
};

export default DeviceItem;
