import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import DeviceHome from './pages/DeviceHome';
import AdminRegister from './pages/admin/AdminRegister';
import AdminLogin from './pages/admin/AdminLogin';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminSMSHome from './pages/admin/AdminSMSHome';

import AdminDevices from './pages/admin/AdminDevices';
import AdminSMSCount from './pages/admin/AdminSMSCount';

import Card from './components/Card'


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<DeviceHome />} />
        <Route path="/admin/register" element={<AdminRegister />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/dash/devices" element={<AdminDevices />} />
        <Route path="/admin/sms/" element={<AdminSMSHome />} />
        <Route path="/admin/sms/count" element={<AdminSMSCount />} />
      </Routes>
    </Router>
  );
};

const Home = () => {
  return (
    <Card title="BIENVENUE" texte="Plateforme Envoi SMS">
      <div className="col-12">
        <a href="/register"
          className="btn btn-outline-danger btn-lg p-3 mt-3 mb-3 w-100">
          <b>Inscription</b>
        </a>
        <a href="/login" className="btn btn-outline-warning btn-lg p-3 w-100">
          <b>Connexion</b>
        </a>
      </div>
    </Card>
  );
};

export default App;

