import React from 'react'
import StatIcone from './StatIcone'

export default function DashCardSms({ titre, nombre, connect, styles, color, actuel }) {
    return (
        <div className="col-md-4">
            <div className={`card info-card ${styles}`}>
                <div className="card-body">
                    <h5 className="card-title">{titre} <span> </span></h5>
                    <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-chat-dots-fill"></i>
                        </div>
                        <div className="ps-3">
                            <h6><b> {nombre}</b> </h6>
                            <span className={`text-${color} pt-1 fw-bold`}>{connect} </span>
                            <span className={`text-info pt-1 fw-bold`}><StatIcone color="info" /> {actuel} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
