import React from 'react';

const FormSelectBox = ({ options, value, onChange, id, labelText }) => {
    return (
        <div className="col-12">
            <label htmlFor={id} className="form-label">{labelText}</label>
            <select className="form-control" value={value} onChange={(e) => onChange(e.target.value)} id={id}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>

    );
};

export default FormSelectBox;
