import React, { useState, useEffect } from 'react';
import AdminHeader from '../../components/dashboard/AdminHeader';
import { BASE_URL } from '../../Config';
import axios from 'axios';
import DashCard from '../../components/DashCard';
import DashCardSms from '../../components/DashCardSms';
import SocketManager from '../../components/SocketInstance';
import PhoneInput from '../../components/PhoneInput';
import FormSelectBox from '../../components/FormSelectBox'
import DeviceItem from '../../components/admin/DeviceItem';
import AdminSidebar from '../../components/dashboard/AdminSidebar';

const socketManager = new SocketManager();

const AdminDashboard = () => {
    const token = localStorage.getItem('admin_token') || null;

    if (token === null) {
        window.location.href = '/admin/login';
    }

    const options = [
        { value: '', label: 'Tous les operateurs' },
        { value: 'djezzy', label: 'Djezzy' },
        { value: 'mobilis', label: 'Mobilis' },
        { value: 'nedjma', label: 'Nedjma' },
    ];

    const statusOptions = [
        { value: '', label: 'Tous les comptes' },
        { value: 'active', label: 'Comptes actif' },
        { value: 'inactive', label: 'Comptes inactifs' },
    ];

    const [operateur, setOperateur] = useState(options[0].value);

    const handleSelectChange = (selectedValue) => {
        setOperateur(selectedValue);
    };

    const [searchStatus, setSearchStatus] = useState(statusOptions[0].value);

    const handleSearchStatus = (selectedValue) => {
        setSearchStatus(selectedValue);

    };

    const [devices, setDevices] = useState([]);
    const [searchPhone, setSearchPhone] = useState('');
    const [d, setD] = useState(0)
    const [m, setM] = useState(0)
    const [n, setN] = useState(0)
    const [dd, setDdd] = useState(0)
    const [dm, setDdm] = useState(0)
    const [dn, setDdn] = useState(0)
    const [ddc, setDdc] = useState(0)
    const [dmc, setDmc] = useState(0)
    const [dnc, setDnc] = useState(0)

    const [statd, setStatd] = useState(0)
    const [statm, setStatm] = useState(0)
    const [statn, setStatn] = useState(0)

    const [dnbrd, setDnbrd] = useState(0)
    const [dnbrm, setDnbrm] = useState(0)
    const [dnbrn, setDnbrn] = useState(0)

    /**** pagination  */
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(1);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        const socket = socketManager.getSocket();
        const DASH_DATA = "DASH_DATA";
        const SERVER_STAT = 'SERVER_STAT'

        socket.on(SERVER_STAT, (data) => {
            if (data !== null) {
                setStatd(data["07"] || 0)
                setStatm(data["06"] || 0)
                setStatn(data["05"] || 0)
            }

            //setStatt(data["total"])

        })

        socket.on(DASH_DATA, (data) => {
            setD(data.d)
            setM(data.m)
            setN(data.n)
            setDdd(data.dd)
            setDdm(data.dm)
            setDdn(data.dn)
            setDdc(data.ddc)
            setDmc(data.dmc)
            setDnc(data.dnc)
            setDnbrd(data.dnbrd)
            setDnbrm(data.dnbrm)
            setDnbrn(data.dnbrn)

        });

    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {

                let url = `${BASE_URL}/api/v1/admin/devices`;

                const queryParams = [];

                queryParams.push(`currentPage=${encodeURIComponent(currentPage)}`);

                if (searchPhone.trim() !== '' && searchPhone.length === 10) {
                    queryParams.push(`phone=${encodeURIComponent(searchPhone)}`);
                }

                if (operateur.trim() !== '') {
                    queryParams.push(`operateur=${encodeURIComponent(operateur)}`);
                }

                if (searchStatus.trim() !== '') {
                    queryParams.push(`status=${encodeURIComponent(searchStatus)}`);
                }

                if (queryParams.length > 0) {
                    url += '?' + queryParams.join('&');
                }

                const response = await axios.get(url, { headers: { Authorization: 'Bearer ' + token } });
                const { devices, count } = response.data;
                setDevices(devices);
                setTotal(count);
            } catch (error) {
                console.error('Erreur:', error.message);
            }
        };

        fetchData();
    }, [searchPhone, currentPage, operateur, searchStatus, token]);

    return (
        <div>
            <AdminHeader />
            <AdminSidebar />
            <section className="section dashboard">
                <main id="main" className="main">
                    <div className="row">
                        <DashCardSms
                            styles="sales-card"
                            titre="Messages sms Djezzy"
                            nombre={d}
                            connect={statd}
                            actuel={dnbrd}
                            color="text-danger"
                        />
                        <DashCardSms
                            styles="revenue-card"
                            titre="Messages sms Mobilis"
                            nombre={m}
                            connect={statm}
                            actuel={dnbrm}
                            color="text-primary"
                        />
                        <DashCardSms
                            styles="customers-card"
                            titre="Messages sms Nedjma"
                            nombre={n}
                            connect={statn}
                            actuel={dnbrn}
                            color="text-primary"
                        />
                    </div>
                    <div className="row">
                        <DashCard
                            styles="sales-card"
                            titre="Appareils Djezzy"
                            nombre={dd}
                            connect={ddc}
                        />
                        <DashCard
                            styles="revenue-card"
                            titre="Appareils mobilis"
                            nombre={dm}
                            connect={dmc}
                        />
                        <DashCard
                            styles="customers-card"
                            titre="Appareils Nedjma"
                            nombre={dn}
                            connect={dnc}
                        />
                    </div>
                    <h2>Liste appareils {total}</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <PhoneInput
                                labelText="Rechercher par téléphone ici ..."
                                name="phone"
                                id="phone"
                                type="text"
                                value={searchPhone}
                                onChange={setSearchPhone}
                            />
                        </div>
                        <div className="col-md-4">
                            <FormSelectBox
                                labelText="Opérateur"
                                id="operator"
                                options={options}
                                value={operateur}
                                onChange={handleSelectChange}
                            />
                        </div>
                        <div className="col-md-4">
                            <FormSelectBox
                                labelText="Statut compte"
                                id="stat"
                                options={statusOptions}
                                value={searchStatus}
                                onChange={handleSearchStatus}
                            />
                        </div>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th colSpan={2}>UTILISATEUR</th>
                                    <th>TELEPHONE</th>
                                    <th>DJZ</th>
                                    <th>MOB</th>
                                    <th>NEJ</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {devices && devices.length > 0 && devices.map(device => (
                                    <DeviceItem key={device.id} id={device.id} device={device} socketManager={socketManager} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {Array.from({ length: Math.ceil(total / 10) }, (_, index) => (
                            <button className={`btn btn-outline-info ms-1 mt-1 ${index + 1 === currentPage ? 'active' : ''}`} key={index + 1} onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </main>
            </section>
        </div>
    );
};

export default AdminDashboard;
