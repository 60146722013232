import React from 'react';
import Header from '../components/dashboard/Header'
import Sidebar from '../components/dashboard/Sidebar';
import QRCode from 'qrcode.react';
import SidebarDisconnect from '../components/dashboard/SidebarDisconnect ';
const DeviceHome = () => {

    const token = localStorage.getItem('token')

    return (
        <div>
            <Header></Header>
            <Sidebar>
                <SidebarDisconnect />
            </Sidebar>
            <main id="main" class="main">
                <p>Votre compte est maintenant actif . Veuillez vous connecter sur l'application mobile</p>
                {token && <QRCode value={token} />}
            </main>
        </div>
    )

}

export default DeviceHome
