import React, { useState } from 'react';
import Card from '../components/Card';
import Forminput from '../components/Forminput';
import Formbutton from '../components/Formbutton';
import Form from '../components/Form';
import PhoneInput from '../components/PhoneInput';
import FormError from '../components/FormError';
import axios from 'axios';
import { BASE_URL } from '../Config';

const Login = () => {

    const token = localStorage.getItem('token') || null;

    if (token !== null) {
        window.location.href = '/dashboard';
    }

    const URL = BASE_URL + '/api/v1/auth/device/login';

    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [formResponse, setFormResponse] = useState('');

    const handleLogin = () => {
        setFormResponse('');

        if (phone === '' || phone.length < 10) {
            setFormResponse('Veuillez compléter votre numéro de téléphone.');
        } else if (password === '') {
            setFormResponse('Veuillez saisir un mot de passe.');
        } else {
            let data = {
                phone: phone,
                password: password
            };

            axios.post(URL, data)
                .then(response => {
                    if (response.data.error) {
                        setFormResponse(response.data.error);
                    } else {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('app_id', response.data.app_id);
                        window.location.href = '/dashboard';
                    }
                })
                .catch(error => {
                    setFormResponse('Erreur serveur: ' + error.message);
                });
        }
    };

    return (
        <div>
            <Card title="Se connecter" texte="">
                <Form onSubmit={handleLogin}>

                    <PhoneInput
                        labelText="Téléphone"
                        name="phone"
                        id="phone"
                        type="text"
                        value={phone}
                        onChange={setPhone}
                    />
                    <Forminput
                        labelText="Mot de passe"
                        name="password"
                        id="password"
                        type="password"
                        invalidFeedbackText="Veuillez insérer votre mot de passe."
                        value={password}
                        onChange={setPassword}
                    />

                    {formResponse && <FormError message={formResponse} />}
                    <Formbutton
                        color="danger"
                        type="submit"
                        texte="Se connecter"
                    />
                    <div className="col-12">
                        <p className="small mb-0">Vous n'avez pas de compte? <a href="/register">S'inscrire</a></p>
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default Login;
